<template>
  <div id="information-list-wrapper">
    <div id="information-list">
      <ul class="information-list">
        <li v-for="(item, index) in data" :key="index">
          <router-link :to="{ name: 'Details', params: { id: item.id } }">
          </router-link>
          <div class="left">
            <div class="information-date">{{ item.date }}</div>
            <div class="information-title">{{ item.title }}</div>
            <div class="information-abstract" v-if="item.profile">
              {{ item.profile }}
            </div>
          </div>
          <div class="right" v-if="item.coverImg">
            <img :src="item.coverImg" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <div class="page">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="dataCount"
        :current-page="page"
        :page-size="pageSize"
        @current-change="handleQuery"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { queryPage } from "@/api/frontArticle";

export default {
  name: "List",
  data() {
    return {
      data: [],
      page: 1,
      pageSize: 10,
      dataCount: 0,
      pageCount: 0,
      search: {
        type: 14
      }
    };
  },
  methods: {
    async handleQuery(page = this.page, pageSize = this.pageSize) {
      this.page = page;
      this.pageSize = pageSize;
      try {
        const res = await queryPage({
          page,
          pageSize,
          param: {
            ...this.search
          }
        });
        this.data = res.data;
        this.page = res.page;
        this.pageSize = res.pageSize;
        this.dataCount = res.dataCount;
        this.pageCount = res.pageCount;
      } catch (e) {
        this.data = [];
        this.page = 1;
        this.pageSize = 10;
        this.dataCount = 0;
        this.pageCount = 0;
      }
    }
  },
  mounted() {
    this.handleQuery();
  }
};
</script>

<style lang="scss" scoped>
#information-list-wrapper {
  width: 1240px;
  margin: 0 auto;
}
#information-list {
  ul {
    margin: 0;
    padding: 0;
    li {
      transition: all ease 0.1s;
      cursor: pointer;
      width: 100%;
      background-color: #f5f5f5;
      padding: 40px 60px;
      margin-bottom: 70px;
      display: flex;
      justify-content: space-between;
      position: relative;
      a {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      .left {
        // width: 386px;
        flex: 1 0 664px;
        padding-right: 70px;
        .information-date {
          font-size: 14px;
          color: #999;
        }
        .information-title {
          font-size: 24px;
          margin: 30px 0;
        }
        .information-abstract {
          line-height: 30px;
        }
      }
      .right {
        flex: 0 0 386px;
        height: 260px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    li:hover {
      background-color: #004898;
      color: #fff;
      .information-date {
        color: #fff;
      }
    }
  }
}
.page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
</style>
